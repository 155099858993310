.customer-card {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
}
.customer-detail-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    width: 100%;
}
.customer-card-body {
    background-color: #f0f2f5;
    padding: 20px;
}

.keys-input {
    cursor: pointer !important;
    width: 100%;
    color: #40a9ff !important;
}
.key-input-button {
    position: absolute;
    right:44px;

}
.key-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.key-label {
    white-space: nowrap;
    margin-right: 10px;
}
.key-value {
 color: #40a9ff;
}
td.ant-table-cell-row-hover {
    background-color: #e6f7ff !important;
}
.ant-table-tbody tr:nth-child(even) {
    background-color: #fafafa
}
.ant-collapse-header:hover {
    background-color: #e6f7ff
}
