.edit-customer-card-body {
    display: flex;
    flex-direction: row;
    width: 100%
}
.edit-customer-card-element {
    width: 50%;
    text-align: start;
    margin: 10px;
    padding: 10px
}
.edit-c-detail-item {
    margin-top: 15px;
    font-size: 18px;
}
.edit-c-detail-item label {
    font-style:italic;
    font-weight: bold;
}
.edit-customer-field {
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 8.5px 14px;
    border: solid 1px #929292;
    border-radius: 5px;
}
