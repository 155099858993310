.cus-detail-item {
    border-bottom: 1px solid #dbdbdb;
    padding: 5px;
    width: 90%;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}
.accordion-title {
    font-weight: bold;
}
.dialog-label {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    margin-right: 10px;
    font-weight: bold;
}
.dialog-input {
    width: 50%;
    display:flex;
    justify-content: flex-start;
}
.dialog-row {
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin: 5px;
}
.dialog-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.detail-i-label {
    color: #40a9ff;
    margin-right: 10px;
}
.copy-secret-button{
    margin-left:10px;
    border:none;
}
