.loader-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.loader-container div span {
    font-size: 22px;
    font-weight: bold;
    color: #40a9ff;
    margin-right: 1px;
}
