.ant-card-hoverable:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgb(0 191 255 / 16%), 0 3px 6px 0 rgb(0 191 255 / 12%), 0 5px 12px 4px rgb(0 191 255 / 9%);
}
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image:url("../../../public/sceenic-bg.jpg");
    opacity: 0.9;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.login-form-container {
    text-align: center;
}
.powered-by {
    position: absolute;
    top:5px;
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
}
.powered-logo {
    background-position: center;
    filter: invert(1);
    background-size: cover;
    width: 100px;
    height: 40px;
    background-image: url("../../../public/sceenic.ico");
}
