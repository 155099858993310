.collapse-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 100%;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
    margin:5px;
    width: 100%;
}
.ant-menu-inline {
    margin: 5px;
}
