.create-customer-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.create-c-form-content{
    width: 50%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
}
.create-customer-field {
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 8.5px 14px;
    border: solid 1px #929292;
    border-radius: 5px;
}
.create-customer-label {
    font-weight: bold;
    margin-top: 5px;
}

