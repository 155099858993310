@import "~antd/dist/antd.css";

.App {
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-image: url("../public/sceenic-bg.jpg");
}
.Alt_App {
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.App section.ant-layout {
  background: none;
}
aside {
  max-height: 100%;
}

.logo-sceenic {
  background-image: url("../public/sceenic.ico");
  width: 200px;
  background-size: cover;
  background-position: center;
  filter: invert(1);
  background-repeat: no-repeat;
  height: 60px;
  margin-left: -40px;
}
.header-logout {
  position: absolute;
  top: 15px;
  right: 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-layout-sider,
.ant-layout-header {
  background: none;
}

.ant-layout-sider {
  height: 100vh;
}

div.ant-collapse-item.panelStatus0 {
  background-color: turquoise;
}

div.ant-collapse-item.panelStatus1 {
  color: yellowgreen;
  margin-right: 5px;
}
div.ant-collapse-item.panelStatus1:nth-child(even) {
  color: #89bc21;
  margin-right: 5px;
}
div.ant-collapse-item.panelStatus-1 {
  color: #FF6347FF;
  margin-right: 5px;
}
div.ant-collapse-item.panel-item:nth-child(even) {
  background-color: #f0f2f3;
}
.input-label-error {
  color: #FF6347FF;
  font-weight: bold;
  font-style: italic;
}
.site-page-header {
  border: 1px solid rgb(235, 237, 240);
  text-transform: capitalize;
  background-color: #fff;
  border-bottom: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 10px 10px;
}
.sceenic-logo {
  width: 200px;
  height: 50px;
  margin: 10px 25px;
  background-position: center;
  background-size: cover;
  background-image: url("../public/sceenic.ico");
  cursor: pointer;
}
.ant-card-head {
  border-bottom: none
}
.status-card {
  text-transform: capitalize;
  margin-right: 5px;
  margin-bottom: 5px;
}
.small-status-card {
  width: 32%
}
.middle-status-card {
  width: 49%
}
.large-status-card {
  width: 100%;
}

.progress-total {
  cursor: pointer
}
.status-dashboard-container {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: center
}
.status-dashboard-container .ant-card-head-wrapper .ant-card-head-title{
  padding-top: 3px;
  padding-bottom: 3px;
}
.customer-panel{
  background-image: url('../public/sceenic-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: absolute;
  top:0;
  left: 0;
  opacity: 0.5;
}
.customer-overlay {
  width: 100%;
  height: 100vh;
  background-color: #000000;
  opacity: 0.5;
}
.customer-detail-label {
  text-align: left;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.customer-detail-label label {
  font-weight: bold;
}
.keys-buttons-panel{
  margin:5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.keys-buttons-panel button {
  margin-left:5px;
}
.collapse-key-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ant-collapse-header-text {
  width: 100%;
}
.customer-table-action {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin:2px;
}
.customer-table-action button {
  margin-left: 2px;
  margin-right: 2px;
}
.form-submit-button {
  border-radius: 25px;
  width: 150px;
  text-transform: uppercase;
}
.submit-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 16px 0px 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: rgb(25, 118, 210);
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  border-radius: 32px;
  width: 100%;
}
